import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
import Header from '../containers/layout/header/header-three'
import Footer from '../containers/layout/footer/footer-one'
import HeroArea from '../containers/index-resolutions/hero-area'
import FeaturesArea from '../containers/index-resolutions/features-area'
import FunFactArea from '../containers/index-resolutions/funfact-area'
import ContactArea from '../containers/global/contact-area/contact-three'
import CTAArea from '../containers/global/cta-area/section-one'
import CalloutsArea from "../containers/index-resolutions/callouts"
import HomeHero from "../containers/index-resolutions/home-hero"



const IndexPage = ({ location}) => (
  <Layout location={location}>
    <SEO title="Resolutions" />
    <Header transparent />
    <main className="site-wrapper-reveal">
      {/* <HeroArea /> */}
      <HomeHero/>
      <FeaturesArea />
      {/* <FunFactArea /> */}
      {/* <TestimonialArea /> */}
      <CTAArea />
      {/* <ServicesArea /> */}
      {/* <WhatsnewArea /> */}
      <CalloutsArea/>
      {/* <HubspotForm/> */}
      <ContactArea />
      {/* <ClientsArea /> */}
    </main>
    <Footer />
  </Layout>
)

export default IndexPage
