import React from 'react'
import "./style.scss"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faGem, faChartBar, faFlag, faStream, faPeopleCarry, faIdCard, faIdCardAlt, faCogs, faUsers, faUserShield, } from '@fortawesome/free-solid-svg-icons'
import { useStaticQuery } from 'gatsby'
library.add(
    faHome,
    faGem,
    faChartBar,
    faFlag,
    faStream,
    faPeopleCarry,
    faIdCardAlt, // for trusted
    faCogs, //for efficient
    faUsers, // for network
    faUserShield, // for trust
   );
const CalloutsArea = (props) => {
    const calloutsData= useStaticQuery(graphql`
    {
        globalYaml(name: {eq: "home"}) {
          bottom_callouts {
            text
            title
          }
        }
      }
      `);

    const callouts = calloutsData.globalYaml.bottom_callouts;
    return (
        <>
            <Container className="calloutsWrapper">
                <Row>
                {callouts.map(callout => (
                    <Col lg={6}>
                    <div key={callout.title} className="calloutItem">
                        <div className="calloutHeader">
                            <FontAwesomeIcon className="fontIcon" icon="id-card-alt" size="2x"/><h5>{callout.title}</h5>
                        </div>
                        <p>{callout.text}</p>
                    </div>
                    </Col>
                ))}
                </Row>
                {/* <Row>
                    <Col lg={6}>
                    <div className="calloutItem">
                        <div className="calloutHeader">
                            <FontAwesomeIcon className="fontIcon" icon="id-card-alt" size="2x"/><h5>Trusted Partners</h5>
                        </div>
                        <p>When you invest with us your interest is ALWAYS our TOP PRIORITY</p>
                     </div>
                    </Col>
                    <Col lg={6}>
                    <div className="calloutItem">
                        <div className="calloutHeader">
                            <FontAwesomeIcon className="fontIcon" icon="stream" size="2x"/><h5>Experienced Operators</h5>
                        </div>
                        <p>Our team has years of experience successfully managing Multifamily investments</p>
                     </div>
                    </Col>
                    <Col lg={6}>
                    <div className="calloutItem">
                        <div className="calloutHeader">
                            <FontAwesomeIcon  className="fontIcon"icon="gem" size="2x"/><h5>Conservative Underwriting</h5>
                        </div>
                        <p>Our philosophy of conservative underwriting significantly reduces investment risks associated with unforeseen events.</p>
                     </div>
                    </Col>
                    <Col lg={6}>
                    <div className="calloutItem">
                        <div className="calloutHeader">
                            <FontAwesomeIcon  className="fontIcon" icon="users" size="2x"/><h5>Expansive Network</h5>
                        </div>
                        <p>Our network of Real Estate and Business Professionals positions us to take full advantage of a variety of investment opportunities.</p>
                     </div>
                    </Col>
                </Row> */}
            </Container>
        </>
    )
}

export default CalloutsArea